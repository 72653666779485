import { getMainList } from '@/service/enroll-activity/index';
import IndexTabbar from '@/components/common/index-tabbar/index.vue'

export default {
    components: {
        IndexTabbar
    },
    data() {
        return {
            loading: true,
            finished: false,
            list: [],
            page: 1
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 获取列表
        async getList() {
            let { list, page } = this
            const res = await getMainList({
                curPage: page,
                limit: 10
            })
            this.loading = false
            this.list = [...list, ...res.records]
            if (res.records.length < 10) this.finished = true
        },
        // 加载
        onLoad() {
            this.page++
            this.getList()
        },
        // 跳转
        onJump(row) {
            switch (row.type) {
                case 10: this.$router.push({ path: '' }); break; // 业绩活动
                case 20: this.$router.push({ path: '' }); break; // 满赠活动
                case 30: this.$router.push({ path: '/luck-draw/index' }); break; // 抽奖活动
                case 40: {
                    this.$router.push({
                        path: '/enroll-activity/special-topic',
                        query: {
                            signupId: row.id
                        }
                    })
                }; break; // 报名活动
                case 60: {
                    this.$router.push({
                        path: '/enroll-activity/retail',
                        query: {
                            signupId: row.id
                        }
                    })
                }; break; // 零售活动
            }
        }
    }
}