import { HttpV1 as HTTP } from "@/request";

// 报名列表
export async function getSignupList(params) {
  return await HTTP.get('/agent/act/signup/list', params);
}

// 代理端提交报名
export async function signup(params) {
    return await HTTP.put('/agent/act/signup', params);
}

// 报名管理套餐查询
export async function getProductList(params) {
    return await HTTP.get('/agent/act/signup/product', params);
}

// 查询直属下级
export async function getMainList(params) {
    return await HTTP.get('/agent/act/main/list', params);
}

// 代理端操作报名记录
export async function signupOperate(params) {
    return await HTTP.post('/agent/act/signup/operate', params);
}

// 代理端操作报名记录
export async function signupDetail(params) {
    return await HTTP.get('/agent/act/signup/detail', params);
}

//  提交活动报名信息时，查询绑定的代理信息（直属下级）
export async function getDirectJuniorsList(params) {
    return await HTTP.get('/agent/auth/direct-juniors', params);
}

//  支付
export async function goWxPay(params) {
    return await HTTP.post('/payment/go-to-pay', params);
}

// 查询零售商品
export async function getActProduct(params) {
    return await HTTP.get('/agent/retail/act/product', params);
}

// 零售订单详情
export async function getActDetail(params) {
    return await HTTP.get('/agent/retail/act/product/detail', params);
}

// 提交订单
export async function postActSubmit(params) {
    return await HTTP.post('/agent/retail/act/submit', params);
}

// 订单列表
export async function getMarketingOrderList(params) {
    return await HTTP.get('/agent/retail/act/retailList', params);
}

// 售后单列表
export async function getAftermarketOrderList(params) {
    return await HTTP.post('/agent/retail/act/retailList', params);
}

// 取消订单
export async function cancelMarketingOrder(params) {
    return await HTTP.get('/agent/retail/act/cancel', params);
}

// 支付订单
export async function payMarketingOrder(params) {
    return await HTTP.post('/agent/retail/act/pay', params);
}

// 申请退款
export async function refundMarketingOrder(params) {
    return await HTTP.get('/agent/retail/act/refund', params);
}

// 确认收货
export async function finishMarketingOrder(params) {
    return await HTTP.get('/agent/retail/act/finish', params);
}

// 获取售后列表
export async function getRefundList(params) {
    return await HTTP.get('/agent/retail/act/refundList', params);
}

// 零售订单详情
export async function getMarketingOrderDetail(params) {
    return await HTTP.get('/agent/retail/act/detail', params);
}

// 售后订单详情
export async function getAftermarketOrderDetail(params) {
    return await HTTP.get('/agent/retail/act/refund/detail', params);
}

// 获取限购数量
export async function getLimitBuy(params) {
    return await HTTP.get('/agent/retail/act/limit/buy', params);
}